<template>
  <div class="dark-layout">
    <div class="auth-wrapper auth-v2">
      <b-row class="auth-inner m-0">
        <!-- Brand logo-->
        <b-link class="brand-logo">
          <!-- <vuexy-logo /> -->
          <!-- <b-img
            fluid
            :src="logoImg"
            alt="Login V2"
            style="max-width: 100px;"
          /> -->
          <!-- <h2 class="brand-text text-primary ml-1">
            TOD
          </h2> -->
        </b-link>
        <!-- /Brand logo-->

        <!-- Left Text-->
        <b-col
          lg="8"
          class="d-none d-lg-flex align-items-center p-5 custom-bg-img"
        >
          <div
            class="w-100 d-lg-flex align-items-center justify-content-center px-5"
          >
            <!-- <b-img
              fluid
              :src="imgUrl"
              alt="Login V2"
            /> -->
          </div>
        </b-col>
        <!-- /Left Text-->

        <!-- Login-->
        <b-col
          lg="4"
          class="d-flex align-items-center auth-bg px-2 p-lg-5 custom-bg-img-right"
        >
          <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
            <b-card-title title-tag="h2" class="font-weight-bold mb-1">
              <b-img fluid :src="logoLogin" alt="Logo" />
            </b-card-title>
            <b-card-text class="mb-2 text-center">
              <b-img fluid :src="logoDescLogin" alt="Logo" style="max-width: 200px;" />
            </b-card-text>

            <!-- form -->
            <validation-observer ref="loginValidation">
              <b-form class="auth-login-form mt-2" @submit.prevent>
                <b-form-group label-for="username">
                  <label for="login-password" style="color:black;">Username</label>
                  <validation-provider
                    #default="{ errors }"
                    name="username"
                    rules="required"
                  >
                    <b-form-input
                      id="username"
                      v-model="username"
                      :state="errors.length > 0 ? false : null"
                      name="username"
                      placeholder=""
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- forgot password -->
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="login-password" style="color:black;">Password</label>
                    <!-- <b-link :to="{ name: 'auth-forgot-password-v2' }">
                      <small>Forgot Password?</small>
                    </b-link> -->
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        placeholder=""
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- checkbox -->
                <!-- <b-form-group>
                  <b-form-checkbox
                    id="remember-me"
                    v-model="status"
                    name="checkbox-1"
                  >
                    Remember Me
                  </b-form-checkbox>
                </b-form-group> -->

                <!-- submit buttons -->
                <b-button
                  type="submit"
                  variant="dark"
                  block
                  @click="validationForm"
                  style="background-color: #e74a2f !important;border-color: #e74a2f !important;"
                >
                  Sign in
                </b-button>
              </b-form>
            </validation-observer>
          </b-col>
        </b-col>
        <!-- /Login-->
      </b-row>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from "bootstrap-vue";
import { required, username } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import loginService from "@/services/loginService.js";
import jwt_decode from "jwt-decode";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "",
      username: "",
      logoLogin: require("@/assets/images/pages/login/tod.png"),
      logoDescLogin: require("@/assets/images/pages/login/smart.png"),
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      logoImg: require("@/assets/images/logo/logo.png"),
      required,
      role: null,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.login();
        }
      });
    },
    login() {
      try {
        loginService
          .login(this.username, this.password)
          .then((response) => {
            if (response.data.status == "error" || response.status == 401) {
              this.$swal("เข้าสู่ระบบไม่สำเร็จ", "Error!", "error");
            } else {
              localStorage.setItem("token", response.data.access_token);
              var token = localStorage.getItem("token");
              var decoded = jwt_decode(token);
              if (decoded.role == "superadmin") {
                this.$router.push("/customer");
              } else {
                this.$router.push("/dashboard");
                // window.location.href = "/report";
              }
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Login Success",
                  icon: "EditIcon",
                  variant: "success",
                },
              });
            }
          })
          .catch();
      } catch (e) {
        throw e;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
.custom-bg-img {
  background-image: url("../assets/images/pages/login/bg-left.png");
  background-position: right;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.custom-bg-img-right {
  // background-image: url("../assets/images/pages/login/bg.png");
  background-color: #eccac7 !important;
  background-position: right;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
// @media screen and (max-width: 992px) {
//   .custom-bg-img-right {
//     background: url("../assets/images/pages/login/login.jpg") center top/100%
//       repeat !important;
//   }
// }
</style>
