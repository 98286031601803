import http from "./http";

export default {
  login(username, password) {
    return http
      .post("users/login", { username: username, password: password })
      .then(function (response) {
        return Promise.resolve(response);
      })
      .catch(function (error) {
        return Promise.resolve(error);
      });
  },
  logout(token) {
    return http
      .post("users/logout", token)
      .then(function (response) {
        return Promise.resolve(response);
      })
      .catch(function (error) {
        return Promise.reject(error);
      });
  },
};
